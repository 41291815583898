// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cream-tsx": () => import("./../../../src/pages/cream.tsx" /* webpackChunkName: "component---src-pages-cream-tsx" */),
  "component---src-pages-face-brightening-kit-tsx": () => import("./../../../src/pages/face-brightening-kit.tsx" /* webpackChunkName: "component---src-pages-face-brightening-kit-tsx" */),
  "component---src-pages-face-cream-face-wash-tsx": () => import("./../../../src/pages/face-cream-face-wash.tsx" /* webpackChunkName: "component---src-pages-face-cream-face-wash-tsx" */),
  "component---src-pages-face-serum-face-wash-tsx": () => import("./../../../src/pages/face-serum-face-wash.tsx" /* webpackChunkName: "component---src-pages-face-serum-face-wash-tsx" */),
  "component---src-pages-facewash-tsx": () => import("./../../../src/pages/facewash.tsx" /* webpackChunkName: "component---src-pages-facewash-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-serum-tsx": () => import("./../../../src/pages/serum.tsx" /* webpackChunkName: "component---src-pages-serum-tsx" */),
  "component---src-pages-skin-brightening-facial-kit-tsx": () => import("./../../../src/pages/skin-brightening-facial-kit.tsx" /* webpackChunkName: "component---src-pages-skin-brightening-facial-kit-tsx" */)
}

